<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import LineChart from './graph.vue'
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
// import RevenueAnalytics from "./revenue";

/**
 * Dashboard component
 */
export default {
	components: {
		Layout,
		// LineChart,
		PageHeader,
		// RevenueAnalytics,
	},
	data() {
		return {
			title: "Dashboard",
			items: [
				{
					text: "Dashboard",
					active: true,
				}
			],
			graph_items: [],
			tenancy_data: [],
			ctc_data: [],
			new_tenancy: 0,
			new_ctc: 0,
			new_member: 0,
			load_api: false
		};
	},
	async created() {
		const result = await axios.get(
			`${process.env.VUE_APP_APIURL}/dashboard`,
			{
				headers: authHeader(),
			}
		).catch(function (error) {
			alert("Error in change password:" + error.message);
		});
		if (result.data.code == 'success') {
			const { data } = result.data;
			this.graph_items = data.tenancy.map(e => e.created_at);
			this.tenancy_data = data.tenancy.map(e => e.item);
			this.ctc_data = data.ctc.map(e => e.item);
			this.new_tenancy = data.total_tenancy;
			this.new_ctc = data.total_ctc;
			this.new_member = data.total_member;
			this.load_api = true;
		}
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="row">
			<div class="col-12 col-sm-6 col-md-4">
				<div class="card tenancy__card">
					<router-link :to="{name: 'Tenancy'}">
						<div class="card-body">
							<h3 class="dashboard_label">Total Tenancy</h3>
							<h1 class="dashboard_data">{{ new_tenancy }}</h1>
						</div>
					</router-link>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-md-4">
				<div class="card ctc__card">
					<router-link :to="{name: 'Ctc'}">
						<div class="card-body">
							<h3 class="dashboard_label">Total CTC</h3>
							<h1 class="dashboard_data">{{ new_ctc }}</h1>
						</div>
					</router-link>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-md-4">
				<div class="card member__card">
					<router-link :to="{name: 'Member'}">
						<div class="card-body">
							<h3 class="dashboard_label">Total Member</h3>
							<h1 class="dashboard_data">{{ new_member }}</h1>
						</div>
					</router-link>
				</div>
			</div>
		</div>

		<!-- <div class="row">
			<div class="col-12 col-md-6">
				<div class="card">
					<div class="card-body">
						<LineChart :items="graph_items" :item_data="tenancy_data" label="Tenancy" v-if="load_api" />
					</div>
				</div>
			</div>

			<div class="col-12 col-md-6">
				<div class="card">
					<div class="card-body">
						<LineChart :items="graph_items" :item_data="ctc_data" label="Ctc" v-if="load_api" />
					</div>
				</div>
			</div>
		</div> -->
	</Layout>
</template>

<style lang="scss">
.dashboard_label {
	font-size: 14px;
	color: #fff;
}

.dashboard_data {
	font-size: 30px;
	color: #fff;
}

.tenancy__card {
	background-image: linear-gradient(to right, #06B6D4, #22D3EE);
	border-radius: 6px;
}

.ctc__card {
	background-image: linear-gradient(to right, #D946EF, #E879F9);
	border-radius: 6px;
}

.member__card {
	background-image: linear-gradient(to right, #3B82F6, #438AF7, #60A5FA);
	border-radius: 6px;
}
</style>